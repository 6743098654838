.project-card-view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .card-text {
    flex-grow: 1;
    text-align: justify;
  }
  
  .fixed-button {
    margin-top: auto;
    align-self: center;
    margin-bottom: 12px; 
  }
  