.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

  }
  
  .modal-wrapper {
    background-color: lightgray ;
    padding: 20px;
    border-radius: 8px;
    width: 620px;
    height: 400px;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    color: black !important;
    border-radius: 8px;
    h1 {
      font-size: 30px;
    }
  }
  


  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-children {
    margin-top: 20px;
  }

  
  .service-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    align-items: stretch;
    margin-top: 8px;
    color: white !important;


    h1 {
      font-size: 30px;
    }
    button {
      margin-top: 20px;
      background-color: #c770f0;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 16px;
    }
  }